import MersenneTwister from 'mersenne-twister'
import qs from 'qs'

window.getQueryParam = function (name, defaultValue = null) {
  const params = qs.parse(window.location.search.slice(1))
  if (typeof params[name] !== 'undefined') {
    return params[name]
  }
  return defaultValue || null
}
const EXPERIMENT_KEY = 'PM_exp'
const isWindow = typeof window !== 'undefined'

function getFromStorage (key) {
  if (typeof window.localStorage !== 'undefined') {
    return window.localStorage.getItem(key)
  }
}

function saveToStorage (key, value) {
  if (typeof window.localStorage !== 'undefined') {
    return window.localStorage.setItem(key, value)
  }
}
function initializeExperiment () {
  let userSegment = getFromStorage(EXPERIMENT_KEY)
  if (!userSegment) {
    const twister = new MersenneTwister()
    userSegment = Math.ceil(twister.random() * 100)
    setTimeout(function () {
      saveToStorage(EXPERIMENT_KEY, userSegment)
    }, 1000)
  }
  console.log('userSegment', userSegment)
  window.userSegment = Number(userSegment)
}

export const onClientEntry = () => {
  if (isWindow) {
    initializeExperiment()
    /**
     * current-device executes with window dependencies, hence placing here
     * window['device'] will be created by current-device
     * without variable assignment, dev build fails
     */
    // eslint-disable-next-line
    var currentDevice = require('current-device').default
  }
}
